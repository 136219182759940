:root {
    --fauxBlack: #262729;
    --white: #fff;
    --marineBlue: #012840;
    --red: #d93636;
    --orange: #f28627;
    --lighterOrange: #f2862717;
    --yellow: #f2a20c;
    --cream: #f2ead0;
    --cremeLight: #f5f0e1;
    --marine80: #345366;
    --marine50: #80939f;
    --marine40: #99a9b3;
    --marine20: #ccd4d9;
    --marine10: #e6eaec;
    --marine5: #f2f4f5;
    --headerBackground: rgba(245, 245, 245, 0.65);
    --transparentColor: rgba(0, 0, 0, 0);
    --darkNavy: #082c43;
    --navy50: #b2bdc5;
    --workWeekHeight: 66px;
    --listHeaderHeight: 50px;

    --secondaryBlue: #1c84fe;
    --mainBlue: #03a8f5;
    --lighterBlue: #1c84fe26;
    --black: #000000;
    --darkGray: #999999;
    --gray: #c4c4c4;
    --whiteGray: #e5e5e5;
    --primaryWhite: #ffffff;
    --lesserPrimaryWhite: #ffffffcc;
    --defaultInputWrapWidth: 270px;
    --shadowColorOnError: 0 0 0 2px rgba(255, 0, 0, 0.2);
}
