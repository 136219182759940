@import 'antd/dist/reset.css';
@import './shared/theme/vars.css';
@import './shared/theme/fonts.css';

body {
    font-family: 'Inter', 'Source-Sans-Pro', Helvetica, Arial, sans-serif;
    font-weight: 500;
    width: fit-content;
    line-height: 1.5715;
    font-variant: tabular-nums;
}

@media only screen and (min-width: 1200px) {
    body {
        width: auto;
    }
}

.flex-grow {
    flex-grow: 1;
}

/* css reset */
body * {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
svg,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Base icon wrapper for button*/
.icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid var(--mainBlue);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
}
.icon-holder path {
    stroke: var(--mainBlue) !important;
}

/*default override for antd modal*/
.ant-modal-content {
    background: url('./shared/theme/assets/images/ModalBackground.svg');
    background-color: white;
    border-radius: 20px 0px !important;
    background-size: cover;
    padding: 0 !important;
}
.ant-modal-content > .ant-modal-header {
    background: none;
    padding: 32px;
    padding-bottom: 16px;
    line-height: 34px;
    border: 0;
    margin: 0;
}
.ant-modal-header > .ant-modal-title {
    font-weight: bold;
    font-size: 24px;
    color: var(--darkNavy);
    line-height: 22px;
}
.ant-modal-content > .ant-modal-body {
    padding: 0px;
}
.ant-modal-content > .ant-modal-footer {
    padding: 0;
    border: 0;
    position: absolute;
    bottom: 0;
    width: 100%;    
}
.ant-modal-content > .ant-modal-close {
    padding: 0;
    margin: 10px;
    height: auto;
    width: auto;
}
.ant-modal-content .ant-modal-close-x {
    padding: 5px;
}
.ant-modal-root .ant-modal-mask {
    background-color: rgba(1,40,64,.75);
    backdrop-filter: blur(20px);
}

.ant-picker-dropdown .ant-picker-cell {
    vertical-align: middle !important;
}

.ant-dropdown-menu {
    padding: 2px 0.5px;
    border-radius: 4px !important;
    border: 1px solid var(--marine20);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 210px;
    overflow: auto;
}

.ant-dropdown {
    background-color: var(--white);
}

/*override default*/
table {
    border-collapse: separate !important;
    border-spacing: 0;
}

/*tooltips inner text div*/
.ant-tooltip-inner {
    font-size: 12px;
    cursor: pointer;
}

/*default override for antd notification*/
.ant-notification-notice-message {
    margin: 0 36px !important;
}

/*scroll style when antd component set virtual=false*/
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
